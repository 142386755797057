<template>
    <div class="backToTop d-flex position-fixed align-items-center justify-content-center cursor-pointer rounded-circle shadow zindex-999" title="Back To Top" @click="backToTop">
        <i class="fas fa-angle-up"></i>
    </div>
</template>

<script>
export default {
    mounted() {
        let btn = document.querySelector('.backToTop');
        window.onscroll = function() {
            var scrollPoint = window.scrollY;
            if(scrollPoint > 500){
                btn.classList.add('show');
            }else{
                btn.classList.remove('show');
            }
        }
    },
    methods: {
        backToTop(){
            window.scrollTo(0, 0)
        }
    }
}
</script>

<style style="scss" scoped>
.backToTop{
    width: 50px; 
    height: 50px; 
    right: 20px; 
    bottom: 40px; 
    font-size: 30px; 
    background: #323B46; 
    color: white;
    opacity: 0;
    transition: opacity 0.4s;
}
.backToTop.show{
    opacity: 1;
}
@media screen and (max-width: 768px) {
    .backToTop{
        width: 40px; 
        height: 40px; 
        font-size: 25px; 
    }
}
</style>