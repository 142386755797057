<template>
  <div>
    <router-view></router-view>
    <Particles/>
    <Toast :message="toastMessage" :status="toastStatus"/>
    <backToTop/>
  </div>
</template>

<script>
import Particles from './components/Particles'
import Toast from './components/Toast'
import backToTop from './components/backToTop'
import {mapGetters} from 'vuex'
export default {
  components: { Particles, Toast, backToTop},
  mounted() {
    window.$('body').Layout();
    window.$('[data-widget="pushmenu"]').PushMenu();
    window.$('[data-widget="control-sidebar"]').ControlSidebar();
    window.$('ul[data-widget="treeview"]').Treeview('init');
    window.$('.dropdown').Dropdown();
    window.$('ul[data-widget="treeview"]').overlayScrollbars();
    document.body.classList.add('sidebar-mini', 'layout-fixed', 'layout-navbar-fixed', 'body-font', 'hold-transition', 'sidebar-collapse');
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#323B46')
  },
  computed: {
    ...mapGetters({
      toastMessage: 'toastMessage',
      toastStatus: 'toastStatus'
    })
  }
}
</script>

<style lang="scss">
  @import '@/assets/sass/app.scss';
</style>