import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import auth from './modules/auth'
import experience from './modules/experience'
import profile from './modules/profile'
import skill from './modules/skill'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        achievements: [],
        btnLoading: false,
        deleteLoading: false,
        experiences: [],
        formErrors: {},
        render: false,
        skills: [],
        skillsModal: [],
        toastMessage: '',
        toastStatus: ''
    },
    mutations: {
        SET_ACHIEVEMENTS(state, data) {
            state.achievements = data
        },
        SET_BUTTON_LOADING(state, status) {
            state.btnLoading = status
        },
        SET_DELETE_LOADING(state, status) {
            state.deleteLoading = status
        },
        SET_EXPERIENCES(state, data) {
            state.experiences = data
        },
        SET_FORM_ERRORS(state, errors) {
            state.formErrors = errors
        },
        SET_RENDER(state, status) {
            state.render = status
        },
        SET_SKILLS(state, data) {
            state.skills = data
        },
        SET_SKILLS_MODALS(state, data) {
            state.skillsModal = data
        },
        SET_TOAST_MESSAGE(state, message) {
            state.toastMessage = message
        },
        SET_TOAST_STATUS(state, status) {
            state.toastStatus = status
        }
    },
    actions: {
        async downloadCV({commit}) {
            commit('SET_BUTTON_LOADING', true)
            setTimeout(() => {
                router.currentRoute.path == '/en' ? commit('SET_TOAST_MESSAGE', 'CV successfully downloaded') : commit('SET_TOAST_MESSAGE', 'CV berhasil di download')
                commit('SET_TOAST_STATUS', 'success')
                commit('SET_BUTTON_LOADING', false)
                window.$('.toast').toast('show')

                setTimeout(() => {
                    window.open('https://drive.google.com/drive/folders/1Ro6W1zgd-fE3-ComPP8jMx2WRPFJJZFj?usp=sharing')
                }, 500)
            }, 1000)

            // try {
            //     let response = await axios.get('download-cv')

            //     if (router.currentRoute.path == '/en') {
            //         commit('SET_TOAST_MESSAGE', 'CV successfully downloaded')
            //     } else {
            //         commit('SET_TOAST_MESSAGE', 'CV berhasil di download')
            //     }
            //     commit('SET_TOAST_STATUS', 'success')
            //     commit('SET_BUTTON_LOADING', false)
            //     window.$('.toast').toast('show')
            //     return response
            // } catch (err) {
            //     commit('SET_BUTTON_LOADING', false)
            //     return err.response
            // }
        },
        async getAchievements({commit}) {
            try {
                let response = await axios.get('content')
                commit('SET_ACHIEVEMENTS', response.data.achievements)
                return response
            } catch (err) {
                return err.response
            }
        },
        async getExperiences({commit}) {
            try {
                let response = await axios.get('content')
                commit('SET_EXPERIENCES', response.data.experiences)
                return response
            } catch (err) {
                return err.response
            }
        },
        async getSkills({commit, getters}) {
            try {
                let response = await axios.get('content')
                if (getters['auth/authenticated']) {
                    commit('SET_SKILLS', response.data.skills)
                } else {
                    commit('SET_SKILLS', response.data.skills.data)
                    commit('SET_SKILLS_MODALS', response.data.skillsModal)
                }
                return response
            } catch (err) {
                return err.response
            }
        }
    },
    getters: {
        achievements(state) {
            return state.achievements
        },
        btnLoading(state) {
            return state.btnLoading
        },
        deleteLoading(state) {
            return state.deleteLoading
        },
        experiences(state) {
            return state.experiences
        },
        formErrors(state) {
            return state.formErrors
        },
        render(state) {
            return state.render
        },
        skills(state) {
            return state.skills
        },
        skillsModal(state) {
            return state.skillsModal
        },
        toastMessage(state) {
            return state.toastMessage
        },
        toastStatus(state) {
            return state.toastStatus
        }
    },
    modules: {
        auth,
        experience,
        profile,
        skill
    }
})