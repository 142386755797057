import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../../router'

Vue.use(Vuex)

export default{
    namespaced: true,
    state: {
        profile: []
    },
    mutations: {
        SET_PROFILE(state, data){
            state.profile = data
        }
    },
    actions: {
        async getProfile({commit}){
            try{
                let response = await axios.get('content');
                if(response.data.profiles){
                    commit('SET_PROFILE', response.data.profiles)
                }
                return response
            }catch(err){
                return err.response
            }
        },
        async storeProfile({commit, dispatch}, credentials){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.post('profiles/en/create', credentials) : axios.post('profiles/id/create', credentials))
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getProfile', null)
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async updateProfile({commit, dispatch}, [id, credentials]){
            commit('SET_BUTTON_LOADING', true, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.put(`profiles/en/update/${id}`, credentials) : axios.put(`profiles/id/update/${id}`, credentials))
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getProfile', null)
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        }
    },
    getters: {
        profile(state){
            return state.profile
        }
    }
}