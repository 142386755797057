import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "404" */ '../components/errors/404.vue'),
  },
  {
    path: '/',
    name: 'home',
    redirect: { name: 'english' }
  },
  {
    path: '/en',
    name: 'english',
    component: () => import(/* webpackChunkName: "en" */ '../views/Home.vue'),
  },
  {
    path: '/id',
    name: 'indonesian',
    component: () => import(/* webpackChunkName: "id" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/pages/Login.vue'),
    beforeEnter: (to, from, next) => {
      if(store.getters['auth/authenticated']){
        return next({
          path: '/en'
        })
      }
      next()
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  // linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})
export default router
