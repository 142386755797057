import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../../router'

Vue.use(Vuex)

export default{
    namespaced: true,
    state: {
        powerOffLoading: false,
        token: '',
        user: []
    },
    mutations: {
        SET_POWER_OFF_LOADING(state, status){
            state.powerOffLoading = status
        },
        SET_TOKEN(state, token){
            state.token = token
        },
        SET_USER(state, data){
            state.user = data
        }
    },
    actions: {
        async login({commit, dispatch}, credentials){
            commit('SET_FORM_ERRORS', {email: null, password: null}, {root: true})
            commit('SET_BUTTON_LOADING', true, {root: true})
            await axios.post('login', credentials).then(response => {
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                router.replace({
                    path: '/'
                })
                return dispatch('attempt', response.data.token)
            }).catch(e => {
                if(e.response.data.errors){
                    commit('SET_FORM_ERRORS', e.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', e.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
            })
        },

        async attempt({commit, state}, token){
            if(token){
                commit('SET_TOKEN', token)
            }

            if(!state.token){
                return
            }

            await axios.get('profile').then(response => {
                commit('SET_USER', response.data)
            }).catch(() => {
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
            })
        },

        async logout({commit, dispatch}){
            commit('SET_POWER_OFF_LOADING', true)
            await axios.post('logout').then(response => {
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_USER', null)
                commit('SET_TOKEN', null)
                commit('SET_POWER_OFF_LOADING', false)
                dispatch('getSkills', null, {root: true})
                window.$('.toast').toast('show')
            })
        }
    },
    getters: {
        authenticated(state){
            return state.token && state.user
        },
        powerOffLoading(state){
            return state.powerOffLoading
        },
        user(state){
            return state.user
        }
    }
}