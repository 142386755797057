import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../../router'

Vue.use(Vuex)

export default{
    namespaced: true,
    state: {
        skill: {}
    },
    mutations: {
        SET_SKILL(state, data){
            state.skill = data
        }
    },
    actions: {
        removeFormErrors({commit}){
            commit('SET_FORM_ERRORS', {}, {root: true})
        },
        async storeSkill({commit, dispatch}, credentials){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.post('skills/en/create', credentials) : axios.post('skills/id/create', credentials));
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getSkills', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async showSkill({commit}, id){
            commit('SET_RENDER', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await axios.get(`skills/${id}`)
                if(response.data.skill){
                    commit('SET_SKILL', response.data.skill)
                }
                commit('SET_RENDER', false, {root: true})
            }catch(err){
                commit('SET_RENDER', false, {root: true})
                return err.response
            }
        },
        async updateSkill({commit, dispatch}, [id, credentials]){
            commit('SET_BUTTON_LOADING', true, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.post(`skills/en/update/${id}?_method=PUT`, credentials) : axios.post(`skills/id/update/${id}?_method=PUT`, credentials))
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getSkills', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async deleteSkill({commit, dispatch}, id){
            commit('SET_DELETE_LOADING', true, {root: true})
            try{
                let response = await axios.delete(`skills/${id}`)
                if(router.currentRoute.path == '/en'){
                    commit('SET_TOAST_MESSAGE', response.data.message_en, {root: true})
                }else{
                    commit('SET_TOAST_MESSAGE', response.data.message_id, {root: true})
                }
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getSkills', null, {root: true})
                return response
            }catch(err){
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        }
    },
    getters: {
        skill(state){
            return state.skill
        }
    }
}