<template>
    <div class="toast alert-position" role="alert" aria-live="assertive" aria-atomic="true" data-animation="true" data-delay="2000" data-autohide="true" :class="{'bg-success': status == 'success', 'bg-danger': status == 'error'}">
        <div class="toast-header">
            <img src="../assets/images/icon/rock.png" class="rounded mr-2" alt="rock" width="20" v-if="status == 'success'">
            <img src="../assets/images/icon/sad.png" class="rounded mr-2" alt="rock" width="20" v-else-if="status == 'error'">
            <strong class="mr-auto" v-if="status == 'success'">{{ $route.path == '/en' ? 'Good job broo' : 'Mantap broo' }}</strong>
            <strong class="mr-auto" v-else-if="status == 'error'">{{$route.path == '/en' ? 'ohh shit man' : 'Ahh yg bener dong' }}</strong>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body">
            {{ message }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['message', 'status'],
    mounted(){
        let toast = document.querySelector('.toast')
        if(!toast.classList.contains('show')){
            toast.classList.add('d-none')
        }
    }
}
</script>

<style lang="scss">
    .alert-position {
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 1000;
    }
    @media screen and (max-width: 768px){
        .alert-position{
            top: 50px;
            bottom: auto;
        }
    }
</style>