import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@fortawesome/fontawesome-free/js/all'
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios'
import VueFroala from 'vue-froala-wysiwyg'
import VueTypedJs from 'vue-typed-js'
import titleMixin from './titleMixin'

export const bus = new Vue();

Vue.mixin(titleMixin)
Vue.use(VueFroala)
Vue.use(VueTypedJs)
axios.defaults.baseURL = 'https://api.ndaruhes.com/api'
// axios.defaults.baseURL = 'http://192.168.1.3:8000/api'

Vue.config.productionTip = false
window.$ = window.jQuery = require('jquery');
AOS.init({
  duration: 850,
});
// Froala
require('froala-editor/js/plugins/link.min.js')
require('froala-editor/js/froala_editor.pkgd.min.js')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

require('@/store/subscriber')
require('bootstrap');
require('overlayscrollbars');
require('admin-lte');

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})