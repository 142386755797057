import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../../router'

Vue.use(Vuex)

export default{
    namespaced: true,
    state:{
        experience: {},
        achievement: {}
    },
    mutations: {
        SET_EXPERIENCE(state, data){
            state.experience = data
        },
        SET_ACHIEVEMENT(state, data){
            state.achievement = data
        }
    },
    actions: {
        // EXPERIENCES
        async storeExperience({commit, dispatch}, credentials){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.post('experiences/en/create', credentials) : axios.post('experiences/id/create', credentials));
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getExperiences', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
					console.log(err.response.data.errors)
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async showExperience({commit}, id){
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await axios.get(`experiences/${id}`)
                if(response.data.experience){
                    commit('SET_EXPERIENCE', response.data.experience)
                }
            }catch(err){
                return err.response
            }
        },
        async updateExperience({commit, dispatch}, [id, credentials]){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.put(`experiences/en/${id}`, credentials) : axios.put(`experiences/id/${id}`, credentials));
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getExperiences', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async deleteExperience({commit, dispatch}, id){
            commit('SET_DELETE_LOADING', true, {root: true})
            try{
                let response = await axios.delete(`experiences/${id}`)
                if(router.currentRoute.path == '/en'){
                    commit('SET_TOAST_MESSAGE', response.data.message_en, {root: true})
                }else{
                    commit('SET_TOAST_MESSAGE', response.data.message_id, {root: true})
                }
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getExperiences', null, {root: true})
                return response
            }catch(err){
                if(router.currentRoute.path == '/en'){
                    commit('SET_TOAST_MESSAGE', err.response.data.message_en, {root: true})
                }else{
                    commit('SET_TOAST_MESSAGE', err.response.data.message_id, {root: true})
                }
                if(err.response.data.message){
                    commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                }
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },

        // ACHIEVEMENTS
        async storeAchievement({commit, dispatch}, credentials){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.post('achievements/en/create', credentials) : axios.post('achievements/id/create', credentials));
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getAchievements', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async showAchievement({commit}, id){
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await axios.get(`achievements/${id}`)
                if(response.data.achievement){
                    commit('SET_ACHIEVEMENT', response.data.achievement)
                }
            }catch(err){
                return err.response
            }
        },
        async updateAchievement({commit, dispatch}, [id, credentials]){
            commit('SET_BUTTON_LOADING', true, {root: true})
            commit('SET_FORM_ERRORS', {}, {root: true})
            try{
                let response = await (router.currentRoute.path == '/en' ? axios.put(`achievements/en/${id}`, credentials) : axios.put(`achievements/id/${id}`, credentials));
                commit('SET_TOAST_MESSAGE', response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getAchievements', null, {root: true})
                return response
            }catch(err){
                if(err.response.data.errors){
                    commit('SET_FORM_ERRORS', err.response.data.errors, {root: true})
                }
                commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_BUTTON_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        },
        async deleteAchievement({commit, dispatch}, id){
            commit('SET_DELETE_LOADING', true, {root: true})
            try{
                let response = await axios.delete(`achievements/${id}`)
                if(router.currentRoute.path == '/en'){
                    commit('SET_TOAST_MESSAGE', response.data.message_en, {root: true})
                }else{
                    commit('SET_TOAST_MESSAGE', response.data.message_id, {root: true})
                }
                commit('SET_TOAST_STATUS', 'success', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                dispatch('getAchievements', null, {root: true})
                return response
            }catch(err){
                if(router.currentRoute.path == '/en'){
                    commit('SET_TOAST_MESSAGE', err.response.data.message_en, {root: true})
                }else{
                    commit('SET_TOAST_MESSAGE', err.response.data.message_id, {root: true})
                }
                if(err.response.data.message){
                    commit('SET_TOAST_MESSAGE', err.response.data.message, {root: true})
                }
                commit('SET_TOAST_STATUS', 'error', {root: true})
                commit('SET_DELETE_LOADING', false, {root: true})
                window.$('.toast').toast('show')
                return err.response
            }
        }
    },
    getters: {
        experience(state){
            return state.experience
        },
        achievement(state){
            return state.achievement
        }
    }
}